<template>
  <models
    :showmodel="showgpsmodel"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @confirm="confirmgps"
    @closemodel="closeshowgps"
    class="gpsmodel"
  >
    <div class="warningtitle">
      <p class="warningcontent">后台自动处理数据,确保每台云手机GPS不同</p>
    </div>
    <el-form class="content" label-position="right" label-width="86px">
      <el-form-item label="拾取经纬度:">
        <el-button
          class="pointbtn"
          @click="navigationTo"
          type="primary"
          size="mini"
          >拾取</el-button
        >
      </el-form-item>
      <el-form-item
        :rules="[{ required: true, message: '请填写经度' }]"
        label="经度:"
      >
        <el-input-number
          v-model="longitude"
          controls-position="right"
          :min="-180"
          :max="180"
          :precision="1"
        ></el-input-number>
      </el-form-item>
      <el-form-item
        :rules="[{ required: true, message: '请填写纬度' }]"
        label="纬度:"
      >
        <el-input-number
          v-model="latitude"
          controls-position="right"
          :min="-90"
          :max="90"
          :precision="1"
        ></el-input-number>
      </el-form-item>
    </el-form>
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
export default {
  props: {
    checkeditem: {
      type: Array,
    },
    showgpsmodel: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      longitude: 0,
      latitude: 0,
      alreadycheckeditem: this.checkeditem,
    };
  },
  watch: {
    checkeditem(val) {
      this.alreadycheckeditem = val;
    },
  },
  components: {
    Models,
  },
  methods: {
    navigationTo() {
      window.open("https://api.map.baidu.com/lbsapi/getpoint/index.html");
    },
    closeshowgps() {
      this.$emit("closeshowgps");
    },
    confirmgps() {
      let PhoneGPSs = [];
      this.alreadycheckeditem &&
        this.alreadycheckeditem.forEach((item, index) => {
          let longrandom = Math.floor((Math.random() + 0.1) * 100000) / 1000000;
          let latrandom = Math.floor(Math.random() * 100000) / 1000000;
          let Altrandom = Math.floor(Math.random() * 50);
          let longitude = this.longitude;
          let latitude = this.latitude;
          let filongitude =
            Math.floor((longitude + longrandom) * 1000000) / 1000000;
          let filatitude =
            Math.floor((latitude + latrandom) * 1000000) / 1000000;
          console.log(filongitude, filatitude);
          if (item.State == "RUNNING") {
            PhoneGPSs.push({
              PhoneId: item.Id,
              Longitude: filongitude,
              Latitude: filatitude,
              Altitude: Altrandom,
            });
          }
        });
      if (PhoneGPSs.length != 0) {
        this.$store
          .dispatch("control/setphonegps", {
            PhoneGPSs,
          })
          .then((res) => {
            if (res.RetCode == 0) {
              this.$message.success("设置成功");
              this.closeshowgps();
            } else {
              this.$message.success("设置失败");
            }
          });
      } else {
        this.roottitle = "请在云手机下方勾选状态为“运行中”的云手机";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.gpsmodel {
  ::v-deep .el-dialog {
    width: 470px;
    height: 415px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  .warningtitle {
    height: 40px;
    margin-bottom: 20px;
    background-color: rgba(228, 229, 241);
    line-height: 40px;
    color: rgb(102, 102, 102);
    font-size: 14px;
    .warningcontent {
      margin-left: 20px;
    }
  }
}
</style>