<template>
  <models
    :showmodel="showworkgroupchange"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @confirm="confirmedit"
    @closemodel="closeworkgroupchange"
    class="ifeditename"
  >
    <div class="maincontent" v-if="productId == 'phone-matrix'">
      <el-row>
        <el-col :span="6">
          <div class="labelname"><span>开启工作组：</span></div>
        </el-col>
        <el-col :span="18">
          <el-button
            type="primary"
            circle
            v-if="workGroupId == '0'"
            @click="changeworkgroup1"
            >&nbsp;1&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup1"
            >&nbsp;1&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-if="workGroupId == '1'"
            @click="changeworkgroup2"
            >&nbsp;2&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup2"
            >&nbsp;2&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-if="workGroupId == '2'"
            @click="changeworkgroup3"
            >&nbsp;3&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup3"
            >&nbsp;3&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-if="workGroupId == '3'"
            @click="changeworkgroup4"
            >&nbsp;4&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup4"
            >&nbsp;4&nbsp;
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div class="maincontent" v-else-if="productId == 'phone-matrix8'">
      <el-row>
        <el-col :span="6">
          <div class="labelname"><span>开启工作组：</span></div>
        </el-col>
        <el-col :span="18">
          <el-button
            type="primary"
            circle
            v-if="workGroupId == '0'"
            @click="changeworkgroup1"
            >&nbsp;1&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup1"
            >&nbsp;1&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-if="workGroupId == '1'"
            @click="changeworkgroup2"
            >&nbsp;2&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup2"
            >&nbsp;2&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-if="workGroupId == '2'"
            @click="changeworkgroup3"
            >&nbsp;3&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup3"
            >&nbsp;3&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-if="workGroupId == '3'"
            @click="changeworkgroup4"
            >&nbsp;4&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup4"
            >&nbsp;4&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-if="workGroupId == '4'"
            @click="changeworkgroup5"
            >&nbsp;5&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup5"
            >&nbsp;5&nbsp; </el-button
          ><el-button
            type="primary"
            circle
            v-if="workGroupId == '5'"
            @click="changeworkgroup6"
            >&nbsp;6&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup6"
            >&nbsp;6&nbsp; </el-button
          ><el-button
            type="primary"
            circle
            v-if="workGroupId == '6'"
            @click="changeworkgroup7"
            >&nbsp;7&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup7"
            >&nbsp;7&nbsp; </el-button
          ><el-button
            type="primary"
            circle
            v-if="workGroupId == '7'"
            @click="changeworkgroup8"
            >&nbsp;8&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup8"
            >&nbsp;8&nbsp;
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div class="maincontent" v-else>
      <el-row>
        <el-col :span="6">
          <div class="labelname"><span>开启工作组：</span></div>
        </el-col>
        <el-col :span="18">
          <el-button
            type="primary"
            circle
            v-if="workGroupId == '0'"
            @click="changeworkgroup1"
            >&nbsp;1&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup1"
            >&nbsp;1&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-if="workGroupId == '1'"
            @click="changeworkgroup2"
            >&nbsp;2&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup2"
            >&nbsp;2&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-if="workGroupId == '2'"
            @click="changeworkgroup3"
            >&nbsp;3&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup3"
            >&nbsp;3&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-if="workGroupId == '3'"
            @click="changeworkgroup4"
            >&nbsp;4&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup4"
            >&nbsp;4&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-if="workGroupId == '4'"
            @click="changeworkgroup5"
            >&nbsp;5&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup5"
            >&nbsp;5&nbsp; </el-button
          ><el-button
            type="primary"
            circle
            v-if="workGroupId == '5'"
            @click="changeworkgroup6"
            >&nbsp;6&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup6"
            >&nbsp;6&nbsp; </el-button
          ><el-button
            type="primary"
            circle
            v-if="workGroupId == '6'"
            @click="changeworkgroup7"
            >&nbsp;7&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup7"
            >&nbsp;7&nbsp; </el-button
          ><el-button
            type="primary"
            circle
            v-if="workGroupId == '7'"
            @click="changeworkgroup8"
            >&nbsp;8&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup8"
            >&nbsp;8&nbsp;
          </el-button>
        </el-col>
      </el-row>
      <br />
      <el-row>
        <el-col :span="6">
          <div class="labelname">
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </div>
        </el-col>
        <el-col :span="18">
          <el-button
            type="primary"
            circle
            v-if="workGroupId == '8'"
            @click="changeworkgroup9"
            >&nbsp;9&nbsp;
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup9"
            >&nbsp;9&nbsp; </el-button
          ><el-button
            type="primary"
            circle
            v-if="workGroupId == '9'"
            @click="changeworkgroup10"
            >10
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup10"
            >10 </el-button
          ><el-button
            type="primary"
            circle
            v-if="workGroupId == '10'"
            @click="changeworkgroup11"
            >11
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup11"
            >11 </el-button
          ><el-button
            type="primary"
            circle
            v-if="workGroupId == '11'"
            @click="changeworkgroup12"
            >12
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup12"
            >12
          </el-button>
          <el-button
            type="primary"
            circle
            v-if="workGroupId == '12'"
            @click="changeworkgroup13"
            >13
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup13"
            >13 </el-button
          ><el-button
            type="primary"
            circle
            v-if="workGroupId == '13'"
            @click="changeworkgroup14"
            >14
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup14"
            >14</el-button
          ><el-button
            type="primary"
            circle
            v-if="workGroupId == '14'"
            @click="changeworkgroup15"
            >15
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup15"
            >15 </el-button
          ><el-button
            type="primary"
            circle
            v-if="workGroupId == '15'"
            @click="changeworkgroup16"
            >16
          </el-button>
          <el-button
            type="primary"
            circle
            v-else
            plain
            @click="changeworkgroup16"
            >16
          </el-button>
        </el-col>
      </el-row>
    </div>
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
export default {
  props: {
    showworkgroupchange: {
      type: Boolean,
    },
    row: {
      type: Object,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
      default: "取消",
    },
    confirmtext: {
      type: String,
      default: "确定",
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
    workGroup1: {
      type: Array,
      default: () => [],
    },
    workGroup2: {
      type: Array,
      default: () => [],
    },
    workGroup3: {
      type: Array,
      default: () => [],
    },
    workGroup4: {
      type: Array,
      default: () => [],
    },
    workGroup5: {
      type: Array,
      default: () => [],
    },
    workGroup6: {
      type: Array,
      default: () => [],
    },
    workGroup7: {
      type: Array,
      default: () => [],
    },
    workGroup8: {
      type: Array,
      default: () => [],
    },
    workGroup9: {
      type: Array,
      default: () => [],
    },
    workGroup10: {
      type: Array,
      default: () => [],
    },
    workGroup11: {
      type: Array,
      default: () => [],
    },
    workGroup12: {
      type: Array,
      default: () => [],
    },
    workGroup13: {
      type: Array,
      default: () => [],
    },
    workGroup14: {
      type: Array,
      default: () => [],
    },
    workGroup15: {
      type: Array,
      default: () => [],
    },
    workGroup16: {
      type: Array,
      default: () => [],
    },

    matrixGroupIds: {
      type: Array,
      default: () => [],
    },
    productId: {
      type: String,
    },
  },
  data() {
    return {
      workGroupId: "0",
      workGroupName: "工作组1",
      matrixGroupIds: [],
    };
  },
  watch: {
    matrixGroupIds(val) {
      this.matrixGroupIds = val;
    },
    productId: function (val) {
      this.productId = val;
    },
  },
  components: {
    Models,
  },
  methods: {
    changeworkgroup1() {
      this.workGroupId = "0";
    },
    changeworkgroup2() {
      this.workGroupId = "1";
    },
    changeworkgroup3() {
      this.workGroupId = "2";
    },
    changeworkgroup4() {
      this.workGroupId = "3";
    },
    changeworkgroup5() {
      this.workGroupId = "4";
    },
    changeworkgroup6() {
      this.workGroupId = "5";
    },
    changeworkgroup7() {
      this.workGroupId = "6";
    },
    changeworkgroup8() {
      this.workGroupId = "7";
    },
    changeworkgroup9() {
      this.workGroupId = "8";
    },
    changeworkgroup10() {
      this.workGroupId = "9";
    },
    changeworkgroup11() {
      this.workGroupId = "10";
    },
    changeworkgroup12() {
      this.workGroupId = "11";
    },
    changeworkgroup13() {
      this.workGroupId = "12";
    },
    changeworkgroup14() {
      this.workGroupId = "13";
    },
    changeworkgroup15() {
      this.workGroupId = "14";
    },
    changeworkgroup16() {
      this.workGroupId = "15";
    },

    async confirmedit() {
      if (this.workGroupId.length == 0) {
        this.$message.error("请先选择要开启的工作组~");
      } else {
        let onPhoneIds = [];
        let offPhoneIds = [];
        // if (this.workGroupId == "1") {
        //   onPhoneIds = this.workGroup2;
        //   offPhoneIds = this.workGroup1.concat(
        //     this.workGroup3,
        //     this.workGroup4,
        //     this.workGroup5,
        //     this.workGroup6,
        //     this.workGroup7,
        //     this.workGroup8
        //   );
        // } else if (this.workGroupId == "2") {
        //   onPhoneIds = this.workGroup3;
        //   offPhoneIds = this.workGroup1.concat(
        //     this.workGroup2,
        //     this.workGroup4,
        //     this.workGroup5,
        //     this.workGroup6,
        //     this.workGroup7,
        //     this.workGroup8
        //   );
        // } else if (this.workGroupId == "3") {
        //   onPhoneIds = this.workGroup4;
        //   offPhoneIds = this.workGroup1.concat(
        //     this.workGroup2,
        //     this.workGroup3,
        //     this.workGroup5,
        //     this.workGroup6,
        //     this.workGroup7,
        //     this.workGroup8
        //   );
        // } else if (this.workGroupId == "4") {
        //   onPhoneIds = this.workGroup5;
        //   offPhoneIds = this.workGroup1.concat(
        //     this.workGroup2,
        //     this.workGroup3,
        //     this.workGroup4,
        //     this.workGroup6,
        //     this.workGroup7,
        //     this.workGroup8
        //   );
        // } else if (this.workGroupId == "5") {
        //   onPhoneIds = this.workGroup6;
        //   offPhoneIds = this.workGroup1.concat(
        //     this.workGroup2,
        //     this.workGroup3,
        //     this.workGroup4,
        //     this.workGroup5,
        //     this.workGroup7,
        //     this.workGroup8
        //   );
        // } else if (this.workGroupId == "6") {
        //   onPhoneIds = this.workGroup7;
        //   offPhoneIds = this.workGroup1.concat(
        //     this.workGroup2,
        //     this.workGroup3,
        //     this.workGroup4,
        //     this.workGroup5,
        //     this.workGroup6,
        //     this.workGroup8
        //   );
        // } else if (this.workGroupId == "7") {
        //   onPhoneIds = this.workGroup8;
        //   offPhoneIds = this.workGroup1.concat(
        //     this.workGroup2,
        //     this.workGroup3,
        //     this.workGroup4,
        //     this.workGroup5,
        //     this.workGroup6,
        //     this.workGroup7
        //   );
        // } else {
        //   onPhoneIds = this.workGroup1;
        //   offPhoneIds = this.workGroup2.concat(
        //     this.workGroup3,
        //     this.workGroup4,
        //     this.workGroup5,
        //     this.workGroup6,
        //     this.workGroup7,
        //     this.workGroup8
        //   );
        // }

        if (this.workGroupId == "1") {
          onPhoneIds = this.workGroup2;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupId == "2") {
          onPhoneIds = this.workGroup3;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupId == "3") {
          onPhoneIds = this.workGroup4;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupId == "4") {
          onPhoneIds = this.workGroup5;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupId == "5") {
          onPhoneIds = this.workGroup6;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupId == "6") {
          onPhoneIds = this.workGroup7;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupId == "7") {
          onPhoneIds = this.workGroup8;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupId == "8") {
          onPhoneIds = this.workGroup9;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupId == "9") {
          onPhoneIds = this.workGroup10;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupId == "10") {
          onPhoneIds = this.workGroup11;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupId == "11") {
          onPhoneIds = this.workGroup12;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupId == "12") {
          onPhoneIds = this.workGroup13;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupId == "13") {
          onPhoneIds = this.workGroup14;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupId == "14") {
          onPhoneIds = this.workGroup15;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup16
          );
        } else if (this.workGroupId == "15") {
          onPhoneIds = this.workGroup16;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15
          );
        } else {
          onPhoneIds = this.workGroup1;
          offPhoneIds = this.workGroup2.concat(
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        }
        let offData = {
          PhoneIds: offPhoneIds,
        };
        try {
          const res = await this.$store
            .dispatch("group/poweroffphone", { ...offData })
            .then((res) => {
              if (res.RetCode == 0) {
                this.$message.success("关闭成功");
              } else {
                this.$message.error("关闭失败！");
              }
            });
        } catch (error) {}

        let onData = {
          PhoneIds: onPhoneIds,
          TeamGroupId: this.workGroupId,
          MatrixGroupIds: this.matrixGroupIds,
        };
        try {
          const res = await this.$store
            .dispatch("group/poweronphone", { ...onData })
            .then((res) => {
              if (res.RetCode == 0) {
                this.$message.success("开启成功");
                this.$emit("confirmedit");
              } else {
                this.$message.error("开启失败！");
                this.$emit("confirmedit");
              }
            });
        } catch (error) {}
        this.closeworkgroupchange();
      }
    },
    closeworkgroupchange() {
      (this.workGroupId = "0"), this.$emit("closeworkgroupchange");
    },
  },
};
</script>
<style lang="scss" scoped>
.ifeditename {
  ::v-deep .el-dialog {
    width: 700px;
    height: 250px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 80px;
  }
  ::v-deep .el-table::before {
    background-color: #fff;
  }
  .labelname {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
  }
  // .maincontent {
  //   margin-top: 10px;
  // }
  .warningtitle {
    height: 40px;
    margin-bottom: 20px;
    background-color: rgba(228, 229, 241);
    line-height: 40px;
    color: rgb(102, 102, 102);
    font-size: 14px;
    .warningcontent {
      margin-left: 20px;
    }
  }

  .tips {
    margin: 36px 0 0 14px;
    color: #a1a1a1;
    font-size: 13px;
  }
}
</style>