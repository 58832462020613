<template>
  <div class="topcontrol-wrap">
    <div class="left">
      <el-checkbox
        :indeterminate="isIndeterminate"
        @change="ifallchecked"
        style="margin-right: 10px; margin-left: 20px"
        v-model="checked"
        >全部</el-checkbox
      >
      <el-switch
        v-model="iftongbu"
        @change="ifconnection"
        active-text="群控操作"
      ></el-switch>
    </div>
    <div class="right">
      <el-button
        class="fullscreen"
        @click="fullscreen"
        type="primary"
        size="mini"
        >全屏</el-button
      >
      <el-button
        v-clipboard:copy="checkedcopy"
        v-clipboard:success="copyphoneid"
        v-clipboard:error="copyerror"
        class="copyid"
        size="mini"
        type="primary"
        >复制云手机ID</el-button
      >
      <span class="solid"></span>
      <el-button
        @click="uploadmodel"
        class="uploadbtn"
        type="primary"
        size="mini"
        :disabled="controldisabled"
        ><i class="iconfont icon-upload"></i>上传文件至云手机</el-button
      >
      <el-popconfirm @confirm="allrestart" :title="restarttitle">
        <el-button
          slot="reference"
          @click="ifallrestart"
          class="restartbtn"
          type="primary"
          size="mini"
          :disabled="controldisabled"
          ><i class="iconfont icon-restart"></i>重启云手机</el-button
        >
      </el-popconfirm>
      <el-dropdown trigger="click" :hide-on-click="false">
        <el-button
          :disabled="controldisabled"
          class="morebtn"
          type="primary"
          size="mini"
          >批量操作</el-button
        >
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <p
              style="display: inline-block; width: 100%"
              @click="showresolumodel"
            >
              批量设置分辨率&DPI
            </p>
          </el-dropdown-item>
          <el-dropdown-item>
            <p style="display: inline-block; width: 100%" @click="showgpsmodel">
              批量设置GPS
            </p>
          </el-dropdown-item>
          <el-dropdown-item>
            <el-popconfirm @confirm="renewphone" :title="renewtitle">
              <p
                slot="reference"
                style="display: inline-block; width: 100%"
                @click="ifrenewphone"
              >
                批量一键改机
              </p>
            </el-popconfirm>
          </el-dropdown-item>
          <el-dropdown-item>
            <el-popconfirm @confirm="changephone" :title="resettitle">
              <p
                slot="reference"
                style="display: inline-block; width: 100%"
                @click="ifchangephone"
              >
                批量一键换机
              </p>
            </el-popconfirm>
          </el-dropdown-item>
          <el-dropdown-item>
            <el-popconfirm @confirm="allroot(true)" :title="roottitle">
              <p
                slot="reference"
                style="display: inline-block; width: 100%"
                @click="isallroot('开启')"
              >
                批量开启root
              </p>
            </el-popconfirm>
          </el-dropdown-item>
          <el-dropdown-item>
            <el-popconfirm @confirm="allroot(false)" :title="roottitle">
              <p
                style="display: inline-block; width: 100%"
                slot="reference"
                @click="isallroot('关闭')"
              >
                批量关闭root
              </p>
            </el-popconfirm>
          </el-dropdown-item>
          <el-dropdown-item>
            <p style="display: inline-block; width: 100%" @click="allsetIp()">
              批量配置IP
            </p>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import { trainNotice } from "@/utils/public";
export default {
  props: {
    isIndeterminate: {
      type: Boolean,
    },
    isallchecked: {
      type: Boolean,
    },
    checkeditem: {
      type: Array,
    },
    controldisabled: {
      type: Boolean,
    },
    PhoneInfos: {
      type: Array,
    },
  },
  data() {
    return {
      checked: this.isallchecked,
      iftongbu: false,
      alreadycheckeditem: this.checkeditem,
      uploatitle: "",
      restarttitle: "",
      roottitle: "",
      resettitle: "",
      renewtitle: "",
      checkedcopy: "",
      sockstitle: "",
    };
  },
  watch: {
    isallchecked(val) {
      this.checked = val;
    },
    checkeditem(val) {
      this.alreadycheckeditem = val;
      this.checkedcopy = val.join("  ");
    },
  },
  methods: {
    copyphoneid(e) {
      if (this.alreadycheckeditem && this.alreadycheckeditem.length) {
        this.$message.success("复制成功");
      } else {
        this.$message.warning("请在云手机下方勾选要操作的云手机");
      }
    },
    copyerror(e) {
      if (this.alreadycheckeditem && this.alreadycheckeditem.length) {
      } else {
        this.$message.warning("请在云手机下方勾选要操作的云手机");
      }
    },
    fullscreen() {
      this.$emit("fullscreen");
    },
    ifallchecked(val) {
      this.$emit("ifallchecked", val);
    },
    ifconnection(val) {
      if (val == true) {
        this.$emit("showifconnect");
        this.$emit("changetongbu", val);
        this.iftongbu = true;
      } else {
        this.$emit("changetongbu", val);
        this.iftongbu = false;
      }
    },
    uploadmodel() {
      if (this.alreadycheckeditem && this.alreadycheckeditem.length) {
        this.$emit("uploadmodel");
      } else {
        this.$message.warning("请在云手机下方勾选要操作的云手机");
      }
    },
    ifallrestart() {
      if (this.alreadycheckeditem && this.alreadycheckeditem.length) {
        this.restarttitle = "重启过程需3-5分钟,确认重启吗?";
      } else {
        this.restarttitle = "请在云手机下方勾选要操作的云手机";
      }
    },
    allrestart() {
      let data = {
        PhoneIds: [],
      };
      if (this.alreadycheckeditem && this.alreadycheckeditem.length) {
        // console.log("lalalo", this.alreadycheckeditem);

        this.alreadycheckeditem.forEach((item, index) => {
          if (item.State == "RUNNING") {
            data.PhoneIds.push(item.Id);
          }
        });
        if (data.PhoneIds.length != 0) {
          this.$store.dispatch("group/phonereboot", { ...data }).then((res) => {
            if (res.RetCode == 0) {
              this.$emit("getphonetask", data);
              this.$message.success("正在重启");
            } else {
              this.$message.error("重启失败！");
            }
          });
        } else {
          this.$message.warning("请在云手机下方勾选状态为“运行中”的云手机");
        }
      } else {
        return;
      }
    },
    showresolumodel() {
      if (this.alreadycheckeditem && this.alreadycheckeditem.length) {
        this.$emit("ifshowresolumodel");
      } else {
        this.$message.warning("请在云手机下方勾选要操作的云手机");
      }
    },
    showgpsmodel() {
      if (this.alreadycheckeditem && this.alreadycheckeditem.length) {
        this.$emit("ifshowgpsmodel");
      } else {
        this.$message.warning("请在云手机下方勾选要操作的云手机");
      }
    },
    ifrenewphone() {
      if (this.alreadycheckeditem && this.alreadycheckeditem.length) {
        this.renewtitle = "一键改机是换机型信息但保留手机数据,确定继续吗？";
      } else {
        this.renewtitle = "请在云手机下方勾选要操作的云手机";
      }
    },
    renewphone() {
      let data = {
        PhoneIds: [],
      };
      if (this.alreadycheckeditem && this.alreadycheckeditem.length) {
        this.alreadycheckeditem.forEach((item, index) => {
          if (item.State == "RUNNING") {
            data.PhoneIds.push(item.Id);
          }
        });
        if (data.PhoneIds.length != 0) {
          this.$store
            .dispatch("control/phonerenew", { ...data })
            .then((res) => {
              if (res.RetCode == 0) {
                this.$emit("getphonetask", data);
                this.$message.success("正在一键改机");
              } else {
                this.$message.success("一键改机失败！");
              }
            });
        } else {
          this.$message.warning("请在云手机下方勾选状态为“运行中”的云手机");
        }
      } else {
        return;
      }
    },
    ifchangephone() {
      if (this.alreadycheckeditem && this.alreadycheckeditem.length) {
        this.resettitle = "一键换机是删除手机和数据并重新创建一台,确定继续吗?";
      } else {
        this.resettitle = "请在云手机下方勾选要操作的云手机";
      }
    },
    changephone() {
      let data = {
        PhoneIds: [],
      };
      if (this.alreadycheckeditem && this.alreadycheckeditem.length) {
        this.alreadycheckeditem.forEach((item, index) => {
          if (item.State == "RUNNING") {
            data.PhoneIds.push(item.Id);
          }
        });
        if (data.PhoneIds.length != 0) {
          this.$store
            .dispatch("control/phonereset", { ...data })
            .then((res) => {
              if (res.RetCode == 0) {
                this.$emit("getphonetask", data);
                this.$message.success("正在一键换机中");
              } else {
                this.$message.success("一键换机失败！");
              }
            });
        } else {
          this.$message.warning("请在云手机下方勾选状态为“运行中”的云手机");
        }
      } else {
        return;
      }
    },
    isallroot(isroot) {
      if (this.alreadycheckeditem && this.alreadycheckeditem.length) {
        this.roottitle = `${isroot}root会重启云手机,确定一键root吗？`;
      } else {
        this.roottitle = "请在云手机下方勾选要操作的云手机";
      }
    },
    allroot(root) {
      let data = {
        PhoneIds: [],
        On: root,
      };
      if (this.alreadycheckeditem && this.alreadycheckeditem.length) {
        this.alreadycheckeditem.forEach((item, index) => {
          if (item.State == "RUNNING") {
            data.PhoneIds.push(item.Id);
          }
        });
        if (data.PhoneIds.length != 0) {
          this.$store.dispatch("control/phoneroot", { ...data }).then((res) => {
            if (res.RetCode == 0) {
              this.$emit("getphonetask", data);
              this.$message.success("操作成功!");
            } else {
              this.$message.success("操作失败!");
            }
          });
        } else {
          this.$message.warning("请在云手机下方勾选状态为“运行中”的云手机");
        }
      } else {
        return;
      }
    },
    allsetIp() {
      let noiplist = [];
      if (this.alreadycheckeditem && this.alreadycheckeditem.length) {
        this.PhoneInfos.forEach((item, index) => {
          this.alreadycheckeditem.forEach((aitem, aindex) => {
            if (
              (item.ProductId == "phone-noip" ||
                item.ProductId == "phone-matrix" || item.ProductId == "phone-matrix8" || item.ProductId == "phone-matrix16") &&
              item.Id == aitem.Id &&
              item.State == "RUNNING"
            ) {
              noiplist.push(aitem);
            }
          });
        });
      }
      if (noiplist.length) {
        this.$emit("showsetipmodel", noiplist);
      } else {
        this.$message.warning(
          "请在云手机下方勾选需要操作的无网版或矩阵版云手机并查看当前云手机的状态是否可用"
        );
      }
    },
    accessNotice() {
      window.open(trainNotice);
    },
  },
};
</script>
<style lang="scss" scoped>
.topcontrol-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  .left {
    width: 300px;
    line-height: 50px;

    .noticebtn {
      margin-left: 10px;
      // font-size: 16px;
      // font-weight: 700;
      // color: #555;
      // cursor: pointer;
    }
    .user-title {
      margin-left: 10px;
    }
  }
  .right {
    width: 600px;
    line-height: 50px;
    padding-right: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .uploadbtn,
    .restartbtn,
    .fullscreen,
    .copyid,
    .morebtn {
      height: 26px;
      margin-left: 8px;
      border-radius: 5px;
      box-shadow: rgb(92 118 232) 0px 2px 4px -1px,
        rgb(0 0 0 / 12%) 0px -3px 0px 0px inset,
        rgb(0 0 0 / 12%) 0px 1px 0px 0px inset;
      background: linear-gradient(
        135deg,
        rgb(100, 136, 252) 0%,
        rgb(56, 96, 244) 100%
      );

      &:hover {
        background: linear-gradient(
          135deg,
          rgb(92, 118, 232) 0%,
          rgb(51, 87, 223) 100%
        );
        box-shadow: rgb(92, 118, 232) 0px 5px 8px -4px,
          rgba(0, 0, 0, 0.12) 0px -3px 0px 0px inset,
          rgba(0, 0, 0, 0.12) 0px 1px 0px 0px inset;
      }
      .icon-upload {
        font-size: 12px;
        margin-right: 5px;
      }
      .icon-restart {
        font-size: 12px;
        margin-right: 5px;
      }
    }
    .solid {
      height: 30px;
      border-right: 2px solid #eee;
      margin-left: 20px;
      margin-right: 15px;
    }
  }
  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-color: rgb(74, 99, 240, 0.93);
    background: rgb(74, 99, 240, 0.93);
  }
  ::v-deep .el-checkbox__inner:hover {
    border-color: rgb(74, 99, 240, 0.93);
  }
  ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: rgb(74, 99, 240, 0.93) !important;
  }
  ::v-deep .el-switch.is-checked .el-switch__core {
    border-color: rgb(74, 99, 240, 0.93);
    background: rgb(74, 99, 240, 0.93);
  }
  ::v-deep .el-switch__label.is-active {
    color: rgb(74, 99, 240, 0.93) !important;
  }
}
</style>