<template>
  <div class="phonecontrol-wrap">
    <div class="bread-wrap">
      <span class="bread-title">云手机操作</span>
      <el-link
        class="user-title"
        href="https://wumitech.com/index/article/detail/id/22"
        type="primary"
        target="_blank"
        >养号攻略</el-link
      >
      <el-link
        class="user-title"
        href="https://wumitech.com/index/article/detail/id/38"
        type="primary"
        target="_blank"
        >操作指南</el-link
      >
    </div>
    <div class="content">
      <div class="grouptree">
        <el-tabs v-model="activeTab">
          <el-tab-pane label="通用云手机" name="general">
            <p class="grouptreetips">选中的云手机将展示在右侧操作界面</p>
            <p class="grouptreetips" style="color: red">
              （注：1、须使用Chrome浏览器。）
            </p>
            <tree
              ref="treefather"
              :props="props"
              nodekey="Id"
              :defaultexpanded="defaultexpanded"
              :treedata="treedata"
              @treecheck="treecheck"
              @handleNodeExpand="handleNodeExpand"
              @handelNodeCollapse="handelNodeCollapse"
            />
          </el-tab-pane>
          <el-tab-pane v-if="ShowMatrixPhone" label="矩阵云手机" name="matrix">
            <p class="grouptreetips">选中的云手机将展示在右侧操作界面</p>
            <p class="grouptreetips" style="color: red">
              （注：1、须使用Chrome浏览器；2、切换工作组时云手机状态为“运行中”和“已关机”才能进行切换；3、主账号不能切换已分配给子账号的云手机。）
            </p>
            <div class="workGroup">
              <el-button
                type="primary"
                class="batchChangeGroup"
                @click="changecheckworkgroup"
                >批量工作组切换</el-button
              >
            </div>
            <tree
              ref="matrixtreefather"
              :props="props"
              nodekey="Id"
              :defaultexpanded="defaultexpanded"
              :treedata="matrixtreedata"
              default-expand-all
              @treecheck="treecheck"
              @handleNodeExpand="handleNodeExpand"
              @handelNodeCollapse="handelNodeCollapse"
            />
          </el-tab-pane>
        </el-tabs>
      </div>

      <div class="phonecontent">
        <div class="phonetop">
          <top-control
            :isIndeterminate="isIndeterminate"
            :isallchecked="isallchecked"
            :checkeditem="checkeditem"
            :controldisabled="controldisabled"
            :PhoneInfos="PhoneInfos"
            @ifallchecked="ifallchecked"
            @showifconnect="showifconnect"
            @ifshowgpsmodel="ifshowgpsmodel"
            @uploadmodel="uploadmodel"
            @changetongbu="changetongbu"
            @getphonetask="getphonetask"
            @ifshowresolumodel="ifshowresolumodel"
            @fullscreen="fullscreen"
            @showsetipmodel="showsetipmodel"
          />
        </div>
        <div class="phoneview">
          <main-control
            ref="maincontrol"
            :pagesize="pagesize"
            :showphonelist="showphonelist"
            @showmodelcontrol="showmodelcontrol"
            @ifitemchecked="ifitemchecked"
          />
          <page-control
            :currentPage="currentPage"
            :pagesize="pagesize"
            :total="total"
            :checkednum="checkednum"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <control-model
      @closemodelcontrol="closemodelcontrol"
      :showmodel="showmodel"
      :data="modeldata"
      :iftongbu="iftongbu"
      :list="phonelist"
      :modeldata="modeldata"
      @singleuploadmodel="singleuploadmodel"
    />
    <if-connect
      :showconnect="showconnect"
      title="系统提示"
      :needcancel="false"
      confirmtext="确认"
      @closeifconnect="closeifconnect"
    />
    <set-resolution
      :showresolumodel="showresolumodel"
      title="批量设置分辨率&Dpi"
      :checkeditem="checkeditem"
      canceltext="取消"
      confirmtext="确认"
      @closeshowresolute="closeshowresolute"
      @getphonetask="getphonetask"
    />
    <set-gps
      :showgpsmodel="showgpsmodel"
      :checkeditem="checkeditem"
      title="批量设置GPS"
      canceltext="取消"
      confirmtext="确认"
      @closeshowgps="closeshowgps"
    />
    <!-- <up-load
      title="上传至云手机"
      :needfooter="false"
      :treedata="treedata"
      :showsingleuploadmodel="showsingleuploadmodel"
      @closeshowsingleupload="closeshowsingleupload"
    /> -->
    <single-up-load
      title="上传至云手机"
      :needfooter="false"
      :treedata="treedata"
      :phoneid="modeldata.Id"
      :phonename="modeldata.Name"
      :row="uploadrow"
      :showsingleuploadmodel="showsingleuploadmodel"
      @closeshowsingleupload="closeshowsingleupload"
    />

    <set-ip-model
      title="批量配置IP"
      :needfooter="false"
      :showsetip="showsetip"
      :checkeditem="noiplist"
      @closesetipmodel="closesetipmodel"
      @showhistroymodel="showhistroymodel"
    />
    <histroy-ip
      :showhistroyip="showhistroyip"
      :needfooter="false"
      title="历史配置"
      @closeshowhistroy="closeshowhistroy"
    />
    <work-group-change
      title="工作组切换"
      :work-group1="workGroup1"
      :work-group2="workGroup2"
      :work-group3="workGroup3"
      :work-group4="workGroup4"
      :work-group5="workGroup5"
      :work-group6="workGroup6"
      :work-group7="workGroup7"
      :work-group8="workGroup8"
      :work-group9="workGroup9"
      :work-group10="workGroup10"
      :work-group11="workGroup11"
      :work-group12="workGroup12"
      :work-group13="workGroup13"
      :work-group14="workGroup14"
      :work-group15="workGroup15"
      :work-group16="workGroup16"
      :productId="currentProductId"
      :showworkgroupchange="showworkgroupchange"
      :matrixGroupIds="matrixGroupIds"
      :workGroupId="currentWorkGroupId"
      @confirmedit="workgroupchangeclick"
      @closeworkgroupchange="closeworkgroupchange"
    />
  </div>
</template>
<script>
import TopControl from "./TopControl/index.vue";
import Tree from "@/components/Tree/index.vue";
import MainControl from "./MainControl/index.vue";
import PageControl from "./PageControl/index.vue";
import ControlModel from "./ControlModel/index.vue";
import IfConnect from "./Models/IfConnect.vue";
import SetGps from "./Models/SetGps.vue";
import SetResolution from "./Models/SetResolution.vue";
import UpLoad from "./Models/UpLoad.vue";
import SingleUpLoad from "./Models/SingleUpLoad.vue";
import SetIpModel from "./Models/SetIpModel.vue";
import { groupconnection } from "@/utils/groupconnection";
import HistroyIp from "./Models/HistroyIp.vue";
import { mapGetters } from "vuex";
import WorkGroupChange from "./Models/WorkGroupChange";

export default {
  name: "phonecontrol",
  data() {
    return {
      props: {
        label: function (data, node) {
          if (node.isLeaf) {
            let index = data.label.indexOf("-");
            let filtlable = data.label.substring(index + 1);
            let label = `${data.uphoneName}(${filtlable})`;
            return label;
          } else {
            return `${data.label}  (${data.children.length})`;
          }
        },
        children: "children",
      },
      controldisabled: false,
      treedata: [],
      matrixtreedata: [],
      phonelist: [],
      showphonelist: [],
      prephonelist: [],
      prevshowphonelist: [],
      checkedphonelist: [],
      currentPage: 1,
      pagesize: 8,
      total: 0,
      showmodel: false,
      modeldata: {},
      iftongbu: false,
      checkednum: 0,
      checkeditem: [],
      isIndeterminate: false,
      isallchecked: false,
      showconnect: false,
      showgpsmodel: false,
      showresolumodel: false,
      showuploadmodel: false,
      showsingleuploadmodel: false,
      timmer: null,
      uploadrow: [],
      rebootphonelist: [],
      norebootphonelist: [],
      showsetip: false,
      PhoneInfos: [],
      MatrixPhoneInfos: [],
      noiplist: [],
      showhistroyip: false,
      treedatavalue: [],
      matrixtreedatavalue: [],
      defaultexpanded: [],
      checkedkeylist: [],
      workGroupId: "",
      workGroup1: [],
      workGroup2: [],
      workGroup3: [],
      workGroup4: [],
      workGroup5: [],
      workGroup6: [],
      workGroup7: [],
      workGroup8: [],
      workGroup9: [],
      workGroup10: [],
      workGroup11: [],
      workGroup12: [],
      workGroup13: [],
      workGroup14: [],
      workGroup15: [],
      workGroup16: [],
      isDisabled: true,
      activeTab: "general",
      MatrixGroups: [],
      checkMatrixGroups: [],
      showworkgroupchange: false,
      currentWorkGroupId: "",
      matrixGroupIds: [],
      matrixphoneselection: [],
      currentProductId: "",
      ShowMatrixPhone: false,
    };
  },
  components: {
    TopControl,
    Tree,
    MainControl,
    PageControl,
    ControlModel,
    IfConnect,
    SetGps,
    UpLoad,
    SingleUpLoad,
    SetResolution,
    SetIpModel,
    HistroyIp,
    WorkGroupChange,
  },
  watch: {
    treedata: {
      handler: function (val) {
        // this.$refs.treefather.$refs.tree.setCheckedKeys(this.checkedkeylist);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["subAccount"]),
  },
  methods: {
    fullscreen() {
      this.$refs.maincontrol.fullplayer();
    },
    ifallchecked(val) {
      this.isIndeterminate = false;
      // this.checked = val;
      this.isallchecked = val;
      if (val == true) {
        this.checkednum = this.phonelist.length;
        this.checkeditem = [];
        this.phonelist.forEach((item) => {
          this.checkeditem.push(item);
        });
        this.checkedphonelist = this.phonelist;
      } else {
        this.checkednum = 0;
        this.checkeditem = [];
        this.checkedphonelist = [];
      }
      this.phonelist = this.phonelist.map((item) => {
        item.itemchecked = val;
        return item;
      });
      this.showphonelist = this.showphonelist.map((item) => {
        item.itemchecked = val;
        return item;
      });
    },
    ifitemchecked(val, value, checkitem) {
      if (val == false) {
        --this.checkednum;
        this.checkeditem.forEach((item, index) => {
          if (item == checkitem) {
            this.checkeditem.splice(index, 1);
          }
        });
        this.checkedphonelist.forEach((item, index) => {
          if (item == checkitem) {
            this.checkedphonelist.splice(index, 1);
          }
        });
      } else {
        ++this.checkednum;
        this.checkeditem.push(checkitem);
        this.checkedphonelist.push(checkitem);
      }
      if (this.phonelist.length == this.checkednum) {
        this.isIndeterminate = false;
        // this.checked = true;
        this.isallchecked = true;
      }
      if (this.phonelist.length > this.checkednum && this.checkednum !== 0) {
        this.isIndeterminate = true;
        this.isallchecked = false;
      }
      if (this.phonelist.length > this.checkednum && this.checkednum == 0) {
        this.isIndeterminate = false;
        // this.checked = false;
        this.isallchecked = false;
      }
      this.phonelist = this.phonelist.map((item) => {
        if (item.value == value) {
          item.itemchecked = val;
        }
        return item;
      });
      // console.log(this.phonelist);
      this.showphonelist = this.showphonelist.map((item) => {
        if (item.value == value) {
          item.itemchecked = val;
        }
        return item;
      });
    },
    disabledcontrol(boolean) {
      this.treedata.forEach((item, index) => {
        let count = 0;
        item.children.forEach((eitem, eindex) => {
          if (this.rebootphonelist.length) {
            this.rebootphonelist.forEach((ritem) => {
              if (ritem == eitem.Id) {
                this.$set(eitem, "disabled", true);
                this.$set(eitem, "State", "REBOOTING");
                ++count;
              }
            });
            if (!this.rebootphonelist.includes(eitem.Id)) {
              this.$set(eitem, "disabled", false);
              this.$set(eitem, "State", "RUNNING");
            }
          } else {
            this.$set(eitem, "disabled", false);
            this.$set(eitem, "State", "RUNNING");
          }
        });
        if (count == item.children.length) {
          this.$set(item, "disabled", true);
        } else {
          this.$set(item, "disabled", false);
        }
      });
      this.matrixtreedata.forEach((item, index) => {
        let count = 0;
        item.children.forEach((eitem, eindex) => {
          if (this.rebootphonelist.length) {
            this.rebootphonelist.forEach((ritem) => {
              if (ritem == eitem.Id) {
                this.$set(eitem, "disabled", true);
                this.$set(eitem, "State", "REBOOTING");
                ++count;
              }
            });
            if (!this.rebootphonelist.includes(eitem.Id)) {
              this.$set(eitem, "disabled", false);
              this.$set(eitem, "State", "RUNNING");
            }
          } else {
            this.$set(eitem, "disabled", false);
            this.$set(eitem, "State", "RUNNING");
          }
        });
        if (count == item.children.length) {
          this.$set(item, "disabled", true);
        } else {
          this.$set(item, "disabled", false);
        }
      });
    },
    //设备状态轮训
    getphonetask(data) {
      this.getphonestatus();
      //重启的设备
      this.rebootphonelist = data.PhoneIds;
      console.log(data);
      this.controldisabled = true;
      console.log("getphonetask: " + JSON.stringify(data));
      //关闭重启的云手机连接
      let list = [];
      this.phonelist.forEach((item) => {
        if (item.itemchecked == false) {
          list.push(item);
        }
      });
      //未重启的设备
      this.norebootphonelist = list;

      groupconnection(list, 0, []);
      // console.log(this.$store.getters.rtclist)
      this.timmer = setInterval(() => {
        this.$store.dispatch("group/getphonelist", data).then((res) => {
          if (res.RetCode == 0) {
            console.log(this.showvideolist);
            res.PhoneInfos.forEach((item) => {
              if (item.State == "RUNNING") {
                item["phoneId"] = item.Id;
                this.rebootphonelist.forEach((ritem, rindex) => {
                  if (ritem == item.Id) {
                    this.norebootphonelist.push(item);
                    console.log(this.norebootphonelist);
                    this.rebootphonelist.splice(rindex, 1);
                    console.log(this.rebootphonelist);
                  }
                });
                groupconnection(this.norebootphonelist);
              }
              if (item.State == "REBOOT_FAILED") {
              }
            });

            if (this.rebootphonelist.length == 0) {
              this.controldisabled = false;
              clearInterval(this.timmer);
            }
          }
        });
      }, 5000);
    },
    treecheck() {
      var checkedlist = [];
      if (this.ShowMatrixPhone) {
        var checkedlist1 = this.$refs.treefather.$refs.tree.getCheckedNodes(
          false,
          true
        );
        var checkedlist2 =
          this.$refs.matrixtreefather.$refs.tree.getCheckedNodes(false, true);
        checkedlist = [...checkedlist1, ...checkedlist2];
        this.checkedkeylist =
          this.$refs.matrixtreefather.$refs.tree.getCheckedKeys();
        this.checkMatrixGroups = [];
        this.checkedkeylist.map((item, index) => {
          if (item.indexOf("matrix") !== -1) {
            this.checkMatrixGroups.push(item);
          }
        });
        console.log("ooooooo", this.checkMatrixGroups.length);
      } else {
        var checkedlist1 = this.$refs.treefather.$refs.tree.getCheckedNodes(
          false,
          true
        );
        // var checkedlist2 =
        //   this.$refs.matrixtreefather.$refs.tree.getCheckedNodes(false, true);
        checkedlist = checkedlist1;
        // this.checkedkeylist =
        //   this.$refs.matrixtreefather.$refs.tree.getCheckedKeys();
        // this.checkMatrixGroups = [];
        // this.checkedkeylist.map((item, index) => {
        //   if (item.indexOf("matrix") !== -1) {
        //     this.checkMatrixGroups.push(item);
        //   }
        // });
        // console.log("ooooooo", this.checkMatrixGroups.length);
      }

      this.prephonelist = [...this.phonelist];
      var oldphoneList = [...this.phonelist];
      var newphoneList = [];
      checkedlist.map((item, index) => {
        if (item.isLeaf == true) {
          newphoneList.push(item);
        }
      });
      this.phonelist = this.getfinnallist(oldphoneList, newphoneList);

      var newcheckphoneList = [];
      checkedlist.map((item, index) => {
        if (item.isLeaf == true && item.itemchecked == true) {
          newcheckphoneList.push(item);
        }
      });
      this.checkedphonelist = newcheckphoneList;
      this.checkeditem = this.checkedphonelist.map((item) => {
        return item;
      });

      this.total = this.phonelist.length;
      if (this.isallchecked == true) {
        this.checkednum = this.phonelist.length;
        this.checkeditem = this.phonelist.map((item) => {
          return item;
        });
        this.checkedphonelist = this.phonelist.map((item) => {
          return item;
        });
      }
      if (this.phonelist.length > this.pagesize) {
        this.filterlist(this.currentPage);
      } else {
        this.prevshowphonelist = this.showphonelist;
        this.showphonelist = this.phonelist;
      }
      this.showvideolist = [];
      for (let i = 0; i < this.phonelist.length; i++) {
        let phoneid = this.phonelist[i].phoneId;
        let pitem = null;
        if (this.existid(this.prephonelist, phoneid)) {
          //已经连接
          if (this.existid(this.prevshowphonelist, phoneid)) {
            if (this.existid(this.showphonelist, phoneid)) {
              pitem = { [phoneid]: "stos" };
            } else {
              pitem = { [phoneid]: "stoh" };
            }
          } else {
            if (this.existid(this.showphonelist, phoneid)) {
              pitem = { [phoneid]: "htos" };
            } else {
              pitem = { [phoneid]: "htoh" };
            }
          }
        } else {
          //未连接
          if (this.existid(this.showphonelist, phoneid)) {
            pitem = { [phoneid]: "ntos" };
          } else {
            pitem = { [phoneid]: "ntoh" }; //从无到显示
          }
        }
        this.showvideolist.push(pitem);
      }
      console.log("当前选中");
      console.log("当前选中", this.phonelist);
      console.log("当前重启");
      console.log(this.rebootphonelist);
      if (this.rebootphonelist.length) {
        let list = JSON.parse(JSON.stringify(this.phonelist));
        console.log(list);
        this.phonelist.forEach((item, index) => {
          this.rebootphonelist.forEach((ritem, rindex) => {
            if (ritem == item.Id) {
              list.forEach((litem, lindex) => {
                if (ritem == litem.Id) {
                  list.splice(lindex, 1);
                }
              });
            }
          });
        });
        this.norebootphonelist = list;
        console.log(this.norebootphonelist);
        groupconnection(this.norebootphonelist, 0, this.showvideolist);
      } else {
        groupconnection(this.phonelist, 0, this.showvideolist);
      }
    },
    getfinnallist(oldlist, newlist) {
      let relist = [];
      let inlist = [];
      let finnallist = [];
      for (let i = 0; i < oldlist.length; i++) {
        let flag = false;
        for (let j = 0; j < newlist.length; j++) {
          if (oldlist[i].phoneId == newlist[j].phoneId) {
            flag = true;
            break;
          }
        }
        if (!flag) {
          relist.push(oldlist[i]);
        }
      }
      for (let i = 0; i < newlist.length; i++) {
        let flag = false;
        for (let j = 0; j < oldlist.length; j++) {
          if (newlist[i].phoneId == oldlist[j].phoneId) {
            flag = true;
            break;
          }
        }
        if (!flag) {
          if (this.isallchecked == true) {
            newlist[i]["itemchecked"] = true;
          } else {
            newlist[i]["itemchecked"] = false;
          }
          inlist.push(newlist[i]);
        }
      }
      if (inlist.length) {
        finnallist = [...oldlist, ...inlist];
      }
      if (relist.length) {
        relist.forEach((ritem, rindex) => {
          oldlist.forEach((oitem, oindex) => {
            if (ritem.phoneId == oitem.phoneId) {
              oldlist.splice(oindex, 1);
            }
          });
        });
        finnallist = oldlist;
      }
      return finnallist;
    },
    filterlist(i) {
      if (
        this.showphonelist.length == 1 &&
        this.phonelist.length < this.prephonelist.length
      ) {
        let startindex = (i - 2) * this.pagesize;
        let endindex = (i - 1) * this.pagesize;
        this.prevshowphonelist = this.showphonelist;
        this.showphonelist = this.phonelist.slice(startindex, endindex);
        this.currentPage = i - 1;
        return;
      }
      let startindex = (i - 1) * this.pagesize;
      let endindex = i * this.pagesize;
      this.prevshowphonelist = this.showphonelist;
      this.showphonelist = this.phonelist.slice(startindex, endindex);
    },
    handleSizeChange(val) {
      this.pagesize = val;
      this.filterlist(1);
      this.currentPage = 1;
      this.checkShowVideo();
    },
    handleCurrentChange(current) {
      this.filterlist(current);
      this.currentPage = current;
      this.checkShowVideo();
    },
    checkShowVideo() {
      this.showvideolist = [];
      for (let i = 0; i < this.phonelist.length; i++) {
        let pitem = null;
        let phoneid = this.phonelist[i].phoneId;
        if (this.existid(this.showphonelist, phoneid)) {
          if (this.existid(this.prevshowphonelist, phoneid)) {
            pitem = { [phoneid]: "stos" }; //从显示到显示
          } else {
            pitem = { [phoneid]: "htos" }; //从隐藏到显示
          }
        } else {
          if (this.existid(this.prevshowphonelist, phoneid)) {
            pitem = { [phoneid]: "stoh" }; //从显示到隐藏
          } else {
            pitem = { [phoneid]: "htoh" }; //从隐藏到隐藏
          }
        }
        this.showvideolist.push(pitem);
      }
      if (this.rebootphonelist.length) {
        groupconnection(this.norebootphonelist, 0, this.showvideolist);
      } else {
        groupconnection(this.phonelist, 0, this.showvideolist);
      }
    },
    resetShowVideoList() {
      //console.log("before: " + JSON.stringify(this.showvideolist));
      var checkedUphones = this.xphoneToUphone(this.checkeditem);
      var svList = [];
      for (let i = 0; i < this.showvideolist.length; i++) {
        var key = Object.keys(this.showvideolist[i]);
        var showflag = Object.values(this.showvideolist[i]);
        for (let j = 0; j < checkedUphones.length; j++) {
          if (checkedUphones[j] == key) {
            if (showflag == "stos" || showflag == "htos") {
              showflag = "ntos";
            }
            if (showflag == "stoh" || showflag == "htoh") {
              showflag = "ntoh";
            }
          }
        }
        let pitem = { [key]: showflag };
        svList.push(pitem);
      }
      this.showvideolist = svList;
      //console.log("after: " + JSON.stringify(this.showvideolist));
    },
    xphoneToUphone(xphones) {
      var uphones = [];
      for (let i = 0; i < xphones.length; i++) {
        for (let j = 0; j < this.phonelist.length; j++) {
          if (xphones[i] == this.phonelist[j].value) {
            uphones.push(this.phonelist[j].phoneId);
            break;
          }
        }
      }
      return uphones;
    },
    existid(list, id) {
      let find = false;
      for (let j = 0; j < list.length; j++) {
        if (id == list[j].phoneId) {
          find = true;
          break;
        }
      }
      return find;
    },
    showmodelcontrol(treeitem, index) {
      if (
        treeitem.State == "RUNNING" ||
        treeitem.State == "RESET_FAILED" ||
        treeitem.State == "SET_ROOT_FAILED" ||
        treeitem.State == "SET_GPS_FAILED" ||
        treeitem.State == "SET_CONFIG_FAILED" ||
        treeitem.State == "SET_SOCKS_FAILED" ||
        treeitem.State == "RENEW_FAILED" ||
        treeitem.State == "REBOOT_FAILED" ||
        treeitem.State == "START_FAILED"
      ) {
        this.modeldata = treeitem;
        if (this.rebootphonelist.includes(treeitem.phoneId)) {
          this.$message.warning("这台设备正在重启");
          return;
        }
        let list = JSON.parse(JSON.stringify(this.phonelist));
        this.phonelist.forEach((item, index) => {
          if (item.phoneId == treeitem.phoneId) {
            list.splice(index, 1);
          }
        });
        if (this.rebootphonelist.length) {
          this.norebootphonelist.forEach((item, index) => {
            if (item.Id == treeitem.phoneId) {
              this.norebootphonelist.splice(index, 1);
            }
          });
          groupconnection(this.norebootphonelist);
        } else {
          groupconnection(list);
        }
        let refsvideo = this.$refs.maincontrol.$refs.videoitem;
        let videoitem = this.getvideoitem(refsvideo, treeitem.phoneId);
        videoitem.clearstatus();
        this.showmodel = !this.showmodel;
      } else {
        this.$message.error("当前云手机不能打开，请稍后再试~");
      }
    },
    closemodelcontrol(modelitem) {
      this.showmodel = !this.showmodel;
      if (this.rebootphonelist.length) {
        this.norebootphonelist.push(modelitem);
        groupconnection(this.norebootphonelist, 500);
      } else {
        groupconnection(this.phonelist, 500);
      }
    },
    getvideoitem(videogroup, phoneId) {
      let selecteditem;
      videogroup.map((item) => {
        if (item.id == phoneId) {
          selecteditem = item;
        }
        return;
      });
      return selecteditem;
    },
    changetongbu(val) {
      this.iftongbu = val;
    },
    showifconnect() {
      this.showconnect = !this.showconnect;
    },
    closeifconnect() {
      this.showconnect = !this.showconnect;
    },
    ifshowresolumodel() {
      this.showresolumodel = !this.showresolumodel;
    },
    closeshowresolute() {
      this.showresolumodel = !this.showresolumodel;
    },
    ifshowgpsmodel() {
      this.showgpsmodel = !this.showgpsmodel;
    },
    showsetipmodel(noiplist) {
      this.showsetip = !this.showsetip;
      this.noiplist = noiplist;
    },
    closesetipmodel() {
      this.showsetip = !this.showsetip;
    },
    showhistroymodel() {
      this.showhistroyip = !this.showhistroyip;
    },
    closeshowhistroy() {
      this.showhistroyip = !this.showhistroyip;
    },
    uploadmodel() {
      this.selection = [];
      for (let item of this.checkedphonelist) {
        let renewform = {
          product_type: "Phone",
          region: item.IpRegion,
          config_type: item.ProductId,
          name: item.Id,
          group: item.GroupName,
          status: item.State,
          cityId: item.CityId,
        };
        this.selection.push(renewform);
      }
      this.uploadrow = this.selection;
      this.showsingleuploadmodel = !this.showsingleuploadmodel;
    },
    singleuploadmodel() {
      this.selection = [];
      for (let item of [this.modeldata]) {
        let renewform = {
          product_type: "Phone",
          region: item.IpRegion,
          config_type: item.ProductId,
          name: item.Id,
          group: item.GroupName,
          status: item.State,
        };
        this.selection.push(renewform);
      }
      this.uploadrow = this.selection;
      this.showsingleuploadmodel = !this.showsingleuploadmodel;
    },

    closeshowgps() {
      this.showgpsmodel = !this.showgpsmodel;
    },
    closeshowupload() {
      this.showsingleuploadmodel = !this.showsingleuploadmodel;
    },
    closeshowsingleupload() {
      this.showsingleuploadmodel = !this.showsingleuploadmodel;
    },
    getWorkGroupId() {
      this.$store.dispatch("group/getmatrixworkgroupid", {}).then((res) => {
        if (res.RetCode == 0) {
          this.workGroupId = res.WorkGroupId;
        }
      });
    },
    getgrouplist() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("group/getgrouplist").then((res) => {
          if (res.RetCode == 0) {
            resolve(res.Groups);
          }
        });
      });
    },

    //获取所有云手机
    getphonelist() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("sub/getphonelist", { PhoneRegion: 1 })
          .then((res) => {
            if (res.RetCode == 0) {
              this.PhoneInfos = res.PhoneInfos;
              // var workGrouptmp = 4 - parseInt(this.workGroupId);
              this.PhoneInfos.sort(function (a, b) {
                // var tmpA = (parseInt(a.TeamGroupId) + workGrouptmp) % 4;
                // var tmpB = (parseInt(b.TeamGroupId) + workGrouptmp) % 4;
                return a.Name > b.Name ? 1 : -1;
              });
              resolve(this.PhoneInfos);
            }
          });
      });
    },
    //获取所有云手机
    getphonelist2() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("sub/getphonelist", { PhoneRegion: 1 })
          .then((res) => {
            if (res.RetCode == 0) {
              this.PhoneInfos = res.PhoneInfos;
              // var workGrouptmp = 4 - parseInt(this.workGroupId);
              this.PhoneInfos.sort(function (a, b) {
                // var tmpA = (parseInt(a.TeamGroupId) + workGrouptmp) % 4;
                // var tmpB = (parseInt(b.TeamGroupId) + workGrouptmp) % 4;
                return a.Name > b.Name ? 1 : -1;
              });
              resolve(this.PhoneInfos);
            }
          });
      });
    },
    getmatrixgrouplist() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("group/getmatrixgrouplist").then((res) => {
          if (res.RetCode == 0) {
            this.MatrixGroups = res.MatrixGroups;
            resolve(this.MatrixGroups);
          }
        });
      });
    },

    gettreedata() {
      Promise.all([this.getgrouplist(), this.getphonelist()]).then(
        ([Groups, PhoneInfos]) => {
          //通用云手机
          const filterlist =
            Groups.length &&
            Groups.filter((item, index) => {
              item["children"] = [];
              PhoneInfos.map((eitem, eindex) => {
                eitem["label"] = eitem.Id;
                eitem["value"] = eitem.Id;
                eitem["phoneId"] = eitem.OutId;
                eitem["uphoneName"] = eitem.Name;
                eitem["createTime"] = eitem.CreateTime;
                eitem["expireTime"] = eitem.ExpireTime;
                eitem["state"] = eitem.State;
                eitem["isLeaf"] = true;
                if (
                  eitem.GroupId == item.Id &&
                  eitem.ProductId != "phone-matrix" &&
                  eitem.ProductId != "phone-matrix8" &&
                  eitem.ProductId != "phone-matrix16"
                ) {
                  item["id"] = index + 1;
                  item["label"] = item.Name;
                  item["isLeaf"] = false;
                  item["children"].push(eitem);
                }
              });
              if (item.children.length !== 0) {
                return true;
              } else {
                return false;
              }
            });

          this.treedatavalue = filterlist;
          this.treedata = this.treedatavalue;
        }
      );
    },
    getmatrixtreedata() {
      Promise.all([
        this.getgrouplist(),
        this.getphonelist2(),
        this.getmatrixgrouplist(),
      ]).then(([Groups, PhoneInfos, MatrixGroups]) => {
        //矩阵云手机
        const matrixfilterlist =
          Groups.length &&
          Groups.filter((item, index) => {
            item["children"] = [];
            MatrixGroups.map((eitem, eindex) => {
              eitem["label"] = eitem.MatrixGroupId;
              eitem["value"] = eitem.MatrixGroupId;
              eitem["matrixGroupId"] = eitem.MatrixGroupId;
              eitem["matrixGroupName"] = eitem.Name;
              eitem["Id"] = eitem.MatrixGroupId;
              eitem["createTime"] = eitem.CreateTime;
              eitem["expireTime"] = eitem.ExpireTime;
              eitem["isLeaf"] = true;
              eitem["children"] = [];
              PhoneInfos.map((pitem, pindex) => {
                pitem["label"] = pitem.Id;
                pitem["value"] = pitem.Id;
                pitem["phoneId"] = pitem.OutId;
                pitem["uphoneName"] = pitem.Name;
                pitem["createTime"] = pitem.CreateTime;
                pitem["expireTime"] = pitem.ExpireTime;
                pitem["state"] = pitem.State;
                pitem["isLeaf"] = true;
                if (pitem.MatrixGroupId == eitem.MatrixGroupId) {
                  eitem["id"] = eindex + 1;
                  eitem["label"] = eitem.Name;
                  eitem["isLeaf"] = false;
                  eitem["children"].push(pitem);
                }
              });

              if (eitem.Group == item.Id) {
                item["id"] = index + 1;
                item["label"] = item.Name;
                item["isLeaf"] = false;
                if (eitem.children.length !== 0) {
                  item["children"].push(eitem);
                } 
              }
            });
            if (item.children.length !== 0) {
              return true;
            } else {
              return false;
            }
          });
        this.matrixtreedatavalue = matrixfilterlist;
        this.matrixtreedata = this.matrixtreedatavalue;
        this.getphonestatus();
      });
    },
    handleNodeExpand(data, node) {
      let flag = false;
      this.defaultexpanded.some((item) => {
        if (item == data["Id"]) {
          flag = true;
          return true;
        }
      });
      if (!flag) {
        this.defaultexpanded.push(data.Id);
      }
    },
    handelNodeCollapse(data, node) {
      this.defaultexpanded.some((item, index) => {
        if (item == data["Id"]) {
          this.defaultexpanded.splice(index, 1);
        }
      });
    },
    discontrolphone(list) {
      this.treedatavalue.forEach((item, index) => {
        let count = 0;
        item.children.forEach((eitem, eindex) => {
          list.forEach((litem, lindex) => {
            if (
              litem.Id == eitem.Id &&
              (litem.State == "RESETTING" ||
                litem.State == "REBOOTING" ||
                litem.State == "CREATING" ||
                litem.State == "SETTING_ROOT" ||
                litem.State == "RENEWING" ||
                litem.State == "CREATE_FAILED")
            ) {
              ++count;
              this.$set(eitem, "disabled", true); //不展示
              this.$set(eitem, "State", litem.State);
            }
            if (
              litem.Id == eitem.Id &&
              litem.State !== "RESETTING" &&
              litem.State !== "REBOOTING" &&
              litem.State !== "CREATING" &&
              litem.State !== "SETTING_ROOT" &&
              litem.State !== "RENEWING" &&
              litem.State !== "CREATE_FAILED"
            ) {
              this.$set(eitem, "disabled", false);
              this.$set(eitem, "State", litem.State);
            }
          });
        });
        if (count == item.children.length) {
          this.$set(item, "disabled", true);
        } else {
          this.$set(item, "disabled", false);
        }
      });

      this.matrixtreedatavalue.forEach((item, index) => {
        let count = 0;
        item.children.forEach((eitem, eindex) => {
          eitem.children.forEach((pitem, pindex) => {
            list.forEach((litem, lindex) => {
              if (
                litem.Id == pitem.Id &&
                (litem.State == "RESETTING" ||
                  litem.State == "REBOOTING" ||
                  litem.State == "CREATING" ||
                  litem.State == "SETTING_ROOT" ||
                  litem.State == "RENEWING" ||
                  litem.State == "CREATE_FAILED")
              ) {
                ++count;
                this.$set(pitem, "disabled", true); //不展示
                this.$set(pitem, "State", litem.State);
              }
              if (
                litem.Id == pitem.Id &&
                litem.State !== "RESETTING" &&
                litem.State !== "REBOOTING" &&
                litem.State !== "CREATING" &&
                litem.State !== "SETTING_ROOT" &&
                litem.State !== "RENEWING" &&
                litem.State !== "CREATE_FAILED"
              ) {
                this.$set(pitem, "disabled", false);
                this.$set(pitem, "State", litem.State);
              }
            });
          });
        });
        if (count == item.children.length) {
          this.$set(item, "disabled", true);
        } else {
          this.$set(item, "disabled", false);
        }
      });
    },
    getphonestatus() {
      var list = [];
      this.$store
        .dispatch("sub/getphonelist", { PhoneRegion: 1 })
        .then((res) => {
          if (res.RetCode == 0) {
            list = [...res.PhoneInfos];
          }
          this.discontrolphone(list);
        });
    },
    getuserinfo() {
      this.$store.dispatch("user/getuserinfo").then((res) => {
        if (res.RetCode == 0) {
          this.ShowMatrixPhone = res.ShowMatrixPhone;
        }
      });
    },
    //获取矩阵云手机列表
    getalltablelist() {
      this.workGroup1 = [];
      this.workGroup2 = [];
      this.workGroup3 = [];
      this.workGroup4 = [];
      this.workGroup5 = [];
      this.workGroup6 = [];
      this.workGroup7 = [];
      this.workGroup8 = [];
      this.workGroup9 = [];
      this.workGroup10 = [];
      this.workGroup11 = [];
      this.workGroup12 = [];
      this.workGroup13 = [];
      this.workGroup14 = [];
      this.workGroup15 = [];
      this.workGroup16 = [];
      return new Promise((resolve, reject) => {
        let data = {
          IsMatrix: "1",
        };
        this.$store.dispatch("group/getphonelist", { ...data }).then((res) => {
          if (res.RetCode == 0) {
            this.MatrixPhoneInfos = res.PhoneInfos;
            var disabled = false;
            this.MatrixPhoneInfos.forEach((item) => {
              if (
                item.State != "RUNNING" &&
                item.State != "STOPPED" &&
                item.State != "CREATE_FAILED"
              ) {
                disabled = true;
              }
              //子账号
              if (this.subAccount) {
                if (item.State != "CREATE_FAILED") {
                  //筛选创建失败
                  if (item.TeamGroupId == "0") {
                    this.workGroup1.push(item.Id);
                  } else if (item.TeamGroupId == "1") {
                    this.workGroup2.push(item.Id);
                  } else if (item.TeamGroupId == "2") {
                    this.workGroup3.push(item.Id);
                  } else if (item.TeamGroupId == "3") {
                    this.workGroup4.push(item.Id);
                  } else if (item.TeamGroupId == "4") {
                    this.workGroup5.push(item.Id);
                  } else if (item.TeamGroupId == "5") {
                    this.workGroup6.push(item.Id);
                  } else if (item.TeamGroupId == "6") {
                    this.workGroup7.push(item.Id);
                  } else if (item.TeamGroupId == "7") {
                    this.workGroup8.push(item.Id);
                  } else if (item.TeamGroupId == "8") {
                    this.workGroup9.push(item.Id);
                  } else if (item.TeamGroupId == "9") {
                    this.workGroup10.push(item.Id);
                  } else if (item.TeamGroupId == "10") {
                    this.workGroup11.push(item.Id);
                  } else if (item.TeamGroupId == "11") {
                    this.workGroup12.push(item.Id);
                  } else if (item.TeamGroupId == "12") {
                    this.workGroup13.push(item.Id);
                  } else if (item.TeamGroupId == "13") {
                    this.workGroup14.push(item.Id);
                  } else if (item.TeamGroupId == "14") {
                    this.workGroup15.push(item.Id);
                  } else {
                    this.workGroup16.push(item.Id);
                  }
                }
              } else {
                if (
                  item.State != "CREATE_FAILED" &&
                  item.UserId == item.OwnerUserId
                ) {
                  //筛选创建失败和子账号
                  if (item.TeamGroupId == "0") {
                    this.workGroup1.push(item.Id);
                  } else if (item.TeamGroupId == "1") {
                    this.workGroup2.push(item.Id);
                  } else if (item.TeamGroupId == "2") {
                    this.workGroup3.push(item.Id);
                  } else if (item.TeamGroupId == "3") {
                    this.workGroup4.push(item.Id);
                  } else if (item.TeamGroupId == "4") {
                    this.workGroup5.push(item.Id);
                  } else if (item.TeamGroupId == "5") {
                    this.workGroup6.push(item.Id);
                  } else if (item.TeamGroupId == "6") {
                    this.workGroup7.push(item.Id);
                  } else if (item.TeamGroupId == "7") {
                    this.workGroup8.push(item.Id);
                  } else if (item.TeamGroupId == "8") {
                    this.workGroup9.push(item.Id);
                  } else if (item.TeamGroupId == "9") {
                    this.workGroup10.push(item.Id);
                  } else if (item.TeamGroupId == "10") {
                    this.workGroup11.push(item.Id);
                  } else if (item.TeamGroupId == "11") {
                    this.workGroup12.push(item.Id);
                  } else if (item.TeamGroupId == "12") {
                    this.workGroup13.push(item.Id);
                  } else if (item.TeamGroupId == "13") {
                    this.workGroup14.push(item.Id);
                  } else if (item.TeamGroupId == "14") {
                    this.workGroup15.push(item.Id);
                  } else {
                    this.workGroup16.push(item.Id);
                  }
                }
              }
            });
            if (disabled == false) {
              this.isDisabled = false;
            }
          }
        });
      });
    },
    getUploadMatrixGroupPhoneList(row) {
      return new Promise(async (resolve, reject) => {
        this.matrixphoneselection = [];
        for (let item of row) {
          let data = {
            MatrixGroupId: item,
          };
          try {
            const res = await this.$store.dispatch("group/getphonelist", {
              ...data,
            });
            if (res.RetCode == 0) {
              for (let eitem of res.PhoneInfos) {
                this.currentProductId = eitem.ProductId;
                this.matrixphoneselection.push(eitem);
              }
            }
          } catch (error) {}
        }
        resolve(this.matrixphoneselection);
      });
    },

    getMatrixGroupPhoneList(row) {
      return new Promise((resolve, reject) => {
        let data = {
          MatrixGroupId: row,
        };
        this.$store.dispatch("group/getphonelist", { ...data }).then((res) => {
          if (res.RetCode == 0) {
            resolve(res.PhoneInfos);
          }
        });
      });
    },
    changecheckworkgroup() {
      if (Object.entries(this.checkMatrixGroups).length == 0) {
        this.$message.warning("至少需选择一个矩阵组");
        return;
      }
      this.matrixGroupIds = this.checkMatrixGroups;
      this.workGroup1 = [];
      this.workGroup2 = [];
      this.workGroup3 = [];
      this.workGroup4 = [];
      this.workGroup5 = [];
      this.workGroup6 = [];
      this.workGroup7 = [];
      this.workGroup8 = [];
      this.workGroup9 = [];
      this.workGroup10 = [];
      this.workGroup11 = [];
      this.workGroup12 = [];
      this.workGroup13 = [];
      this.workGroup14 = [];
      this.workGroup15 = [];
      this.workGroup16 = [];
      Promise.all([
        this.getUploadMatrixGroupPhoneList(this.checkMatrixGroups),
      ]).then(([PhoneInfos]) => {
        try {
          PhoneInfos.forEach((item) => {
            //子账号
            if (this.subAccount) {
              if (item.State != "CREATE_FAILED") {
                //筛选创建失败
                if (item.TeamGroupId == "0") {
                  this.workGroup1.push(item.Id);
                } else if (item.TeamGroupId == "1") {
                  this.workGroup2.push(item.Id);
                } else if (item.TeamGroupId == "2") {
                  this.workGroup3.push(item.Id);
                } else if (item.TeamGroupId == "3") {
                  this.workGroup4.push(item.Id);
                } else if (item.TeamGroupId == "4") {
                  this.workGroup5.push(item.Id);
                } else if (item.TeamGroupId == "5") {
                  this.workGroup6.push(item.Id);
                } else if (item.TeamGroupId == "6") {
                  this.workGroup7.push(item.Id);
                } else if (item.TeamGroupId == "7") {
                  this.workGroup8.push(item.Id);
                } else if (item.TeamGroupId == "8") {
                  this.workGroup9.push(item.Id);
                } else if (item.TeamGroupId == "9") {
                  this.workGroup10.push(item.Id);
                } else if (item.TeamGroupId == "10") {
                  this.workGroup11.push(item.Id);
                } else if (item.TeamGroupId == "11") {
                  this.workGroup12.push(item.Id);
                } else if (item.TeamGroupId == "12") {
                  this.workGroup13.push(item.Id);
                } else if (item.TeamGroupId == "13") {
                  this.workGroup14.push(item.Id);
                } else if (item.TeamGroupId == "14") {
                  this.workGroup15.push(item.Id);
                } else {
                  this.workGroup16.push(item.Id);
                }
              }
            } else {
              if (
                item.State != "CREATE_FAILED" &&
                item.UserId == item.OwnerUserId
              ) {
                console.log("sssss", item);

                //筛选创建失败和子账号
                if (item.TeamGroupId == "0") {
                  this.workGroup1.push(item.Id);
                } else if (item.TeamGroupId == "1") {
                  this.workGroup2.push(item.Id);
                } else if (item.TeamGroupId == "2") {
                  this.workGroup3.push(item.Id);
                } else if (item.TeamGroupId == "3") {
                  this.workGroup4.push(item.Id);
                } else if (item.TeamGroupId == "4") {
                  this.workGroup5.push(item.Id);
                } else if (item.TeamGroupId == "5") {
                  this.workGroup6.push(item.Id);
                } else if (item.TeamGroupId == "6") {
                  this.workGroup7.push(item.Id);
                } else if (item.TeamGroupId == "7") {
                  this.workGroup8.push(item.Id);
                } else if (item.TeamGroupId == "8") {
                  this.workGroup9.push(item.Id);
                } else if (item.TeamGroupId == "9") {
                  this.workGroup10.push(item.Id);
                } else if (item.TeamGroupId == "10") {
                  this.workGroup11.push(item.Id);
                } else if (item.TeamGroupId == "11") {
                  this.workGroup12.push(item.Id);
                } else if (item.TeamGroupId == "12") {
                  this.workGroup13.push(item.Id);
                } else if (item.TeamGroupId == "13") {
                  this.workGroup14.push(item.Id);
                } else if (item.TeamGroupId == "14") {
                  this.workGroup15.push(item.Id);
                } else {
                  this.workGroup16.push(item.Id);
                }
              }
            }
            //判断状态如果有一个不是处于下面状态
            if (
              item.State != "RUNNING" &&
              item.State != "STOPPED" &&
              item.State != "CREATE_FAILED"
            ) {
              throw new error();
            }
          });
        } catch (error) {
          this.$message.warning(
            "当前存在云手机不处于”运行中“或”已关机“状态，暂时不能切换工作组，请稍后再试~"
          );
          return;
        }
        if (
          this.workGroup1.length == 0 &&
          this.workGroup2.length == 0 &&
          this.workGroup3.length == 0 &&
          this.workGroup4.length == 0
        ) {
          this.$message.warning(
            "请检查是否勾选了当前主账号下面矩阵组，子账号下面的矩阵组不能切换~"
          );
          return;
        }
        if (
          (this.workGroup5.length > 0 &&
            this.workGroup1.length != this.workGroup5.length) ||
          (this.workGroup9.length > 0 &&
            this.workGroup1.length != this.workGroup9.length) ||
          (this.workGroup9.length > 0 &&
            this.workGroup5.length != this.workGroup9.length)
        ) {
          console.log("kakaka", this.workGroup1.length, this.workGroup5.length);
          this.$message.warning("请选择同一规格的项目进行切换~");

          return;
        } else {
          this.showworkgroupchange = !this.showworkgroupchange;
        }
      });
    },

    closeworkgroupchange() {
      this.showworkgroupchange = !this.showworkgroupchange;
    },
    workgroupchangeclick() {},

    //   //检测矩阵云手机列表
    //   checkalltablelist() {
    //     this.MatrixGroups.forEach((mitem) => {
    //       var workGroup1 = [];
    //       var workGroup2 = [];
    //       var workGroup3 = [];
    //       var workGroup4 = [];
    //       Promise.all([this.getMatrixGroupPhoneList(mitem.MatrixGroupId)]).then(
    //         async ([PhoneInfos]) => {
    //           try {
    //             PhoneInfos.forEach((item) => {
    //               //子账号
    //               if (this.subAccount) {
    //                 if (item.State != "CREATE_FAILED") {
    //                   if (item.TeamGroupId == mitem.MatrixWorkGroupId) {
    //                     if (item.State != "RUNNING") {
    //                       if (item.TeamGroupId == "0") {
    //                         workGroup1.push(item.Id);
    //                       } else if (item.TeamGroupId == "1") {
    //                         workGroup2.push(item.Id);
    //                       } else if (item.TeamGroupId == "2") {
    //                         workGroup3.push(item.Id);
    //                       } else {
    //                         workGroup4.push(item.Id);
    //                       }
    //                     }
    //                   } else {
    //                     if (item.State == "RUNNING") {
    //                       if (item.TeamGroupId == "0") {
    //                         workGroup1.push(item.Id);
    //                       } else if (item.TeamGroupId == "1") {
    //                         workGroup2.push(item.Id);
    //                       } else if (item.TeamGroupId == "2") {
    //                         workGroup3.push(item.Id);
    //                       } else {
    //                         workGroup4.push(item.Id);
    //                       }
    //                     }
    //                   }
    //                 }
    //               } else {
    //                 if (
    //                   item.State != "CREATE_FAILED" &&
    //                   item.UserId == item.OwnerUserId
    //                 ) {
    //                   //筛选
    //                   if (item.TeamGroupId == mitem.MatrixWorkGroupId) {
    //                     if (item.State != "RUNNING") {
    //                       //筛选创建失败和子账号
    //                       if (item.TeamGroupId == "0") {
    //                         workGroup1.push(item.Id);
    //                       } else if (item.TeamGroupId == "1") {
    //                         workGroup2.push(item.Id);
    //                       } else if (item.TeamGroupId == "2") {
    //                         workGroup3.push(item.Id);
    //                       } else {
    //                         workGroup4.push(item.Id);
    //                       }
    //                     }
    //                   } else {
    //                     if (item.State == "RUNNING") {
    //                       //筛选创建失败和子账号
    //                       if (item.TeamGroupId == "0") {
    //                         workGroup1.push(item.Id);
    //                       } else if (item.TeamGroupId == "1") {
    //                         workGroup2.push(item.Id);
    //                       } else if (item.TeamGroupId == "2") {
    //                         workGroup3.push(item.Id);
    //                       } else {
    //                         workGroup4.push(item.Id);
    //                       }
    //                     }
    //                   }
    //                 }
    //               }
    //               //判断状态如果有一个不是处于下面状态
    //               if (
    //                 item.State != "RUNNING" &&
    //                 item.State != "STOPPED" &&
    //                 item.State != "CREATE_FAILED"
    //               ) {
    //                 throw new error();
    //               }
    //             });
    //           } catch (error) {
    //             return;
    //           }

    //           let onPhoneIds = [];
    //           let offPhoneIds = [];
    //           if (mitem.MatrixWorkGroupId == "1") {
    //             onPhoneIds = workGroup2;
    //             offPhoneIds = workGroup1.concat(workGroup3, workGroup4);
    //           } else if (mitem.MatrixWorkGroupId == "2") {
    //             onPhoneIds = workGroup3;
    //             offPhoneIds = workGroup1.concat(workGroup2, workGroup4);
    //           } else if (mitem.MatrixWorkGroupId == "3") {
    //             onPhoneIds = workGroup4;
    //             offPhoneIds = workGroup1.concat(workGroup2, workGroup3);
    //           } else {
    //             onPhoneIds = workGroup1;
    //             offPhoneIds = workGroup2.concat(workGroup3, workGroup4);
    //           }
    //           if (offPhoneIds.length == 0 && onPhoneIds.length == 0) {
    //             return;
    //           }

    //           // //关闭其他当前
    //           let offData = {
    //             PhoneIds: offPhoneIds,
    //           };

    //           try {
    //             const res = await this.$store
    //               .dispatch("group/poweroffphone", { ...offData })
    //               .then((res) => {
    //                 if (res.RetCode == 0) {
    //                   //   this.$message.success("关闭成功");
    //                   // } else {
    //                   //   this.$message.error("关闭失败！");
    //                 }
    //               });
    //           } catch (error) {}

    //           //开启
    //           let onData = {
    //             PhoneIds: onPhoneIds,
    //             TeamGroupId: mitem.MatrixWorkGroupId,
    //           };
    //           try {
    //             const res = await this.$store
    //               .dispatch("group/poweronphone", { ...onData })
    //               .then((res) => {
    //                 if (res.RetCode == 0) {
    //                   //   this.$message.success("开启成功");
    //                   // } else {
    //                   //   this.$message.error("开启失败！");
    //                 }
    //               });
    //           } catch (error) {}
    //         }
    //       );
    //     });
    //   },
  },

  mounted() {},
  activated() {
    this.getuserinfo();
    this.getWorkGroupId();
    //获取分组树
    this.gettreedata();

    this.getmatrixtreedata();
    //清空状态刷新定时器
    if (this.phonestatustimmer !== null) {
      clearInterval(this.phonestatustimmer);
    }
    //刷新分组树状态
    this.phonestatustimmer = setInterval(() => {
      this.getphonestatus();
    }, 5000);

    // //清空状态刷新定时器
    // if (this.phonechangetimmer !== null) {
    //   clearInterval(this.phonechangetimmer);
    // }
    // //刷新分组树状态
    // this.phonechangetimmer = setInterval(() => {
    //   this.checkalltablelist();
    // }, 60000);
  },
  deactivated() {
    this.treedata = [];
    this.matrixtreedata = [];
    this.phonelist = [];
    this.showphonelist = [];
    this.prephonelist = [];
    this.prevshowphonelist = [];
    this.modeldata = {};
    this.checkeditem = [];
    groupconnection(this.phonelist, 0, []);
    this.$notify.close();
    this.checkedphonelist = [];
    clearInterval(this.timmer);
    this.disabledcontrol(false);
    this.controldisabled = false;
    this.rebootphonelist = [];
    this.norebootphonelist = [];
    clearInterval(this.phonestatustimmer);
    // clearInterval(this.phonechangetimmer);
    this.defaultexpanded = [];
    this.checkedkeylist = [];
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>