<template>
  <models
    :showmodel="showhistroyip"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    :needfooter="needfooter"
    @confirm="confirmhistroy"
    @closemodel="closeshowhistroy"
    class="histroymodel"
    @opened="opened"
  >
    <com-table
      ref="comtable"
      :headercellstyle="headercellstyle"
      :pagination="pagination"
      @rowClassName="rowClassName"
      tablesize="small"
      tableheight="550"
      style="width: 100%"
      :columns="ipcolumns"
      :data="IpConfig"
    />
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
import ComTable from "../../../components/Table/index.vue";
export default {
  props: {
    showhistroyip: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
    needfooter: {
      type: Boolean,
      default: true,
    },
  },
  components: { Models, ComTable },
  data() {
    return {
      ipcolumns: [
        {
          prop: "xh",
          label: "序号",
          width: "60",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "phoneId",
          label: "云手机ID",
          width: "100",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "socks5",
          label: "协议",
          width: "80",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "Uri",
          label: "IP",
          width: "80",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "Port",
          label: "端口",
          width: "80",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "UserName",
          label: "账号",
          width: "80",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
        },
      ],
      pagination: {
        current: 1,
        size: 20,
        total: 1,
      },
      IpConfig: [],
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
    };
  },
  methods: {
    opened() {
      var IpConfig = [];
         let data = {
          IsMatrix: "0",
        };
        this.$store.dispatch("group/getphonelist", { ...data }).then((res) => {
        if (res.RetCode == 0) {
          res.PhoneInfos.forEach((item, index) => {

            if ( item.IpConfig != null) {
              let obj = {
                phoneId: item.Id,
                ...item.IpConfig.Socks,
              };
              IpConfig.push(obj);
            }
          });
          this.IpConfig = IpConfig;
        }
      });
    },
    rowClassName(row, rowIndex) {
      row.xh = rowIndex + 1;
      row.socks5 = "socks5";
    },
    confirmhistroy() {},
    closeshowhistroy() {
      this.$emit("closeshowhistroy");
    },
  },
};
</script>
<style lang="scss" scoped>
.histroymodel {
  ::v-deep .el-dialog {
    width: 1000px;
    height: 715px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    padding: 12px 20px;
  }
}
</style>